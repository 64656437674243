<template>
  <v-container
    id="nutritions"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="nutritions"
      :search="search"
      sort-by="id"
      :sort-desc="true"
      :no-data-text="str['nutrition_plans_empty']"
      :no-results-text="str['nutrition_plans_empty']"
      class="elevation-1"
      @click:row="openEditNutrition"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-dialog
            v-model="dialogNutrition"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editPermission"
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewNutrition()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['nutrition_base'] }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in data"
                      :key="item.id"
                      cols="12"
                      v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                    >
                      <v-text-field
                        v-if="item.type === 'input'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-textarea
                        v-if="item.type === 'textarea'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                        hide-details
                      />
                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeNutrition"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  v-if="editPermission"
                  color="success"
                  @click="saveNutrition"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.url="{ item }">
        <div
          class="text-center"
          style="width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 0 auto;"
        >
          <a
            target="_blank"
            :href="item.url"
            @click.stop
          >
            {{ item.url }}
          </a>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteNutrition(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('base_nutrition_edit')
      const headers = [
        { text: window.strings['id'], value: 'id', align: 'left' },
        { text: window.strings['name'], value: 'name', align: 'left' },
      ]

      if (editPermission) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center' })
      }

      return {
        str: window.strings,
        user: user,
        editPermission: editPermission,
        nutritions: [],
        dialogNutrition: false,
        data: [],
        search: '',
        headers: headers,
        nutritionToEdit: null,
      }
    },
    watch: {
      dialogNutrition (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }

        val || this.closeNutrition()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getDataFields()
      this.getNutritions()
    },
    methods: {
      getDataFields: function () {
        const config = this.user && this.user.configurations ? this.user.configurations : null
        const data = [{
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          visible: true,
          required: true,
        }]

        if (config && config.nutrition && config.nutrition.show_type) {
          data.push({
            type: 'select',
            id: 'type',
            title: window.strings['type'],
            items: Utils.getNutritionTypes(),
            value: '',
            visible: true,
          })
        }
        if (config && config.nutrition && config.nutrition.show_proposal) {
          data.push({
            type: 'checkbox',
            id: 'proposal',
            title: window.strings['monthly_proposal'],
            value: '',
            visible: true,
          })
        }
        if (config && config.nutrition && config.nutrition.show_video) {
          data.push({
            type: 'input',
            id: 'video_url',
            title: window.strings['video_url'],
            value: '',
            visible: true,
          })
        }
        if (config && config.nutrition && config.nutrition.show_calories) {
          data.push({
            type: 'input',
            id: 'calories',
            title: window.strings['calories'],
            value: '',
            number: true,
            visible: true,
          })
        }
        data.push({
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
          visible: true,
        })

        this.data = data
      },
      getNutritions: function () {
        const self = this
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

        let filtered = true
        if (nutritionConfiguration && nutritionConfiguration.show_type) {
          filtered = false
        }

        const payload = {
          id: null,
          filtered: filtered,
          fields: ['id', 'date', 'name', 'type']
        }

        Api.getNutritionPlans(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.nutritions = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openNewNutrition () {
        this.setData()
        this.nutritionToEdit = null
        this.dialogNutrition = true
      },
      openEditNutrition (nutrition) {
        const self = this
        const payload = {
          id: nutrition.id
        }
        this.$isLoading(true)
        Api.getNutritionPlan(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const src = response.data[0]
            self.setData(src)
            self.nutritionToEdit = src
            self.dialogNutrition = true
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      setData: function (nutrition) {
        for (let i = 0; i < this.data.length; i++) {
          if (nutrition) {
            if (this.data[i].id === 'proposal') {
              if (nutrition[this.data[i].id]) {
                this.data[i].value = true
              } else {
                this.data[i].value = false
              }
            } else {
              this.data[i].value = nutrition[this.data[i].id]
            }
          } else {
            this.data[i].value = ''
          }
        }
      },
      closeNutrition () {
        this.nutritionToEdit = null
        this.dialogNutrition = false
      },
      saveNutrition: function () {
        const self = this
        const data = this.getNutritionData()

        this.$isLoading(true)

        if (data.id) {
          Api.editNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          delete data.id
          Api.newNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getNutritionData: function () {
        const nutrition = {
          base: 1,
          type: 1,
        }

        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === 'proposal') {
            nutrition[this.data[i].id] = this.data[i].value ? 1 : 0
          } else {
            if (this.data[i].number) {
              nutrition[this.data[i].id] = parseFloat(this.data[i].value)
            } else {
              nutrition[this.data[i].id] = this.data[i].value
            }
          }
        }

        if (this.nutritionToEdit && this.nutritionToEdit.id) {
          nutrition.id = this.nutritionToEdit.id
        }

        return nutrition
      },
      deleteNutrition (nutrition) {
        const self = this
        const nutritionId = nutrition.id
        const payload = {
          id: nutritionId,
        }

        this.$confirm(
          window.strings['want_delete_nutrition_plan'] + (nutrition ? (' "' + nutrition.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteNutritionPlan(payload, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.nutritions.length; i++) {
                if (self.nutritions[i].id === nutritionId) {
                  self.nutritions.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
    },
  }
</script>
